import React from 'react';
import { config } from '../utils/config';
import { useHistory } from 'react-router-dom';

export const GoogleAnalyticsContext = React.createContext([]);

export function useGoogleAnalytics() {
  return React.useContext(GoogleAnalyticsContext);
}

export function GoogleAnalyticsProvider({ children }) {
  const [useAnalytics, setUseAnalytics] = React.useState(true);
  let history = useHistory();

  React.useEffect(() => {
    // On every page change, send an event to Google analytics
    history.listen((location) => {
      if (typeof gtag === 'function') {
        gtag('config', config('google_analytics_id'), { 'page_path': location.pathname });
      }
    });
  }, []);

  React.useEffect(() => {
    if (useAnalytics) {
      attachGoogleAnalyticsScript();
    } else {
      detachGoogleAnalyticsScript();
    }
  }, [useAnalytics]);

  function isAnalyticsLoaded() {
    return typeof gtag === 'function';
  }

  function attachGoogleAnalyticsScript() {
    if (!isAnalyticsLoaded()) {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.setAttribute('async', '');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config('google_analytics_id');
      document.getElementsByTagName('head')[0].appendChild(script);

      let gtagScript = document.createElement('script');
      gtagScript.setAttribute('data-id', 'gtagFunctions');
      gtagScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '`+ config('google_analytics_id') + `');
      `;
      document.getElementsByTagName('head')[0].appendChild(gtagScript);
    }
  }

  function detachGoogleAnalyticsScript() {
    if (isAnalyticsLoaded()) {
      const script = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=' + config('google_analytics_id') + '"]');
      script.parentNode.removeChild(script);

      const gtagScript = document.querySelector('script[data-id="gtagFunctions"]');
      gtagScript.parentNode.removeChild(gtagScript);

      gtag = undefined;
    }
  }

  return (
    <GoogleAnalyticsContext.Provider value={{ 
      enable: () => setUseAnalytics(true), 
      disable: () => setUseAnalytics(false),
      isAnalyticsLoaded: isAnalyticsLoaded(),
    }}
    >
      {children}
    </GoogleAnalyticsContext.Provider>
  );
}