import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import styles from '../../styles';
import mobileStyles from '../../styles/mobile';

export default function Contact(props) {
  
  const responsiveStyle = props.isMobile ? mobileStyles : styles;

  return (
    <Container fluid="md">
      <NavBar isMobile={props.isMobile} />
      <Jumbotron style={responsiveStyle.content}>
        <h1 style={styles.mainTitle}>CONTACT INFORMATION</h1>
        <p style={responsiveStyle.contentText}>
          <strong>Fitness Plus Equipment Services, Inc.</strong><br />
          650 N Main Center<br />
          Suite 101<br />
          St. Charles, MO 63301<br />
          Phone: 636.379.1952<br />
          Fax: 636.634.3699
        </p>
      </Jumbotron>
      <Footer />
    </Container>
  );
}
