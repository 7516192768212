import React from 'react';
import { config } from '../../utils/config';
import { useGoogleAnalytics } from '../../hooks/GoogleAnalyticsContext';

export default function SetGoogleAnalytics() {
  const {enable, disable} = useGoogleAnalytics();

  React.useEffect(() => {
      const useAnalytics = config('inProduction') ? false : true;

      useAnalytics ? enable() : disable();
  }, []);

  return <></>;
}