import React from 'react';
import { Container, Jumbotron, Card, Col, Row } from 'react-bootstrap';
import { useAjax } from '@developers/frp-library/Hooks/useAjax';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import styles from '../../styles';
import mobileStyles from '../../styles/mobile';
import KeithJohnson from '../../images/keith_johnson.jpg';
import * as jobsAjax from '../../ajax/job-listings';

export default function Resources(props) {
  const [jobListings, getJobListings, getJobListingsMeta] = useAjax(jobsAjax.getJobListings, [], true);

  React.useEffect(() => {
    getJobListings();
  }, []);

  const responsiveStyle = props.isMobile ? mobileStyles : styles;

  return (
    <Container fluid="md">
      <NavBar isMobile={props.isMobile} />
      <Jumbotron style={responsiveStyle.content}>
        <h1 style={styles.mainTitle}>RESOURCES</h1>
        <h3 style={styles.secondaryTitle}>PRESS RELEASES</h3>
        <div style={responsiveStyle.contentText}>
          <Card style={{ width: '100%', marginBottom: 15 }}>
            <Card.Body>
              <Card.Title>
              <strong>April 5, 2021</strong> - <a href="/assets/Keith_Johnson_Press_Release_2.pdf" target="_blank">Keith Johnson Joins Fitness Plus as President</a>
              </Card.Title>
              <Row>
                <Col md={2} style={{ display: 'block' }}>
                  <img src={KeithJohnson} style={{ width: 170, height: 120, borderRadius: 3 }} />
                </Col>
                <Col md={10} style={{ paddingTop: 10 }}>
                  <Card.Text style={{ fontSize: '.85em' }}>
                    Fitness Plus Equipment Services, Inc. (Fitness Plus) is pleased to 
                    announce the appointment of Keith Johnson as the organization’s new
                    President as of April 5, 2021. Johnson brings to this position his 
                    wealth of knowledge and experience in leadership, operations/training,
                    and business development from his more than 20 years in the field...<a href="/assets/Keith_Johnson_Press_Release_2.pdf" target="_blank">Read more</a>
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <strong>February 22, 2018</strong> - <a href="/assets/FRP-BH-Fitness-Press-Release.pdf" target="_blank">FitnessRepairParts.com to Become BH North America's Parts Distributor</a><br />
          <strong>April 30, 2014</strong> - <a href="/assets/Press-Release-2014-April-30-Green-Light-Solar-Panels.pdf" target="_blank">Solar Panels Installed at Headquaters</a><br />
          <strong>September 13, 2011</strong> - <a href="/assets/Press Release-2011-September 13-Inc5000-Continued Growth-Local.pdf" target="_blank">Recognition on the Inc5000 For Second Consecutive Year</a><br />
          <strong>March 16, 2011</strong> - <a href="/assets/Press-Release-2011-March-16-Move-Top-Young-Entrepenuer.pdf" target="_blank">Recognized Among St. Louis Top Young Entrepreneurs</a>
        </div>

        <h3 style={styles.secondaryTitle}>NEWSLETTER ARCHIVES</h3>
        <p style={responsiveStyle.contentText}>
          <em>No newsletters yet.</em>
        </p>

        <h3 style={styles.secondaryTitle}>LINKS</h3>
        <p style={responsiveStyle.contentText}>
          <a href="https://fitnessrepairparts.com">
            FitnessRepairParts.com - Find spare or replacement parts for your exercise equipment.
          </a>
        </p>
        <h3 style={styles.secondaryTitle}>JOB OPENINGS</h3>
        <p style={responsiveStyle.contentText}>
          <strong>FITNESS PLUS</strong> is a fast-growing company and we are looking to fill positions with qualified candidates who are 
          interested in growing with us. We offer <strong>paid holidays, paid vacation time, dental insurance, retirement plan, 
          company profit sharing, and many more</strong>. We are looking for individuals who will work hard and can adapt quickly 
          to changes as we go through rapid growth.
        </p>
        {!getJobListingsMeta.loading && jobListings.length === 0 ?
          <div>None at this time. Please check back soon.</div>
        : null}
        {jobListings.map((listing) => (
          <p key={listing.id} style={responsiveStyle.contentText}>
            <a href={listing.file} target="_blank">{listing.title}</a> <strong>{listing.full_time ? "(FULL-TIME)" : "(PART-TIME)"}</strong> {listing.description}
          </p>
        ))}
      </Jumbotron>
      <Footer />
    </Container>
  );
}
