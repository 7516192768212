import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Resources from './Resources';
import Contact from './Contact';

export default function App() {

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 720 ? true : false);
 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true);
    } else {
        setIsMobile(false);
    }
  }
  
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  })

  return (
    <Router>
      <Switch>
        <Route 
          exact 
          path="/" 
          render={(props) => <Home {...props} isMobile={isMobile} />} 
        />
        <Route 
          exact 
          path="/about" 
          render={(props) => <About {...props} isMobile={isMobile} />} 
        />
        <Route 
          exact 
          path="/resources" 
          render={(props) => <Resources {...props} isMobile={isMobile} />} 
        />
        <Route 
          exact 
          path="/contact" 
          render={(props) => <Contact {...props} isMobile={isMobile} />} 
        />
      </Switch>
    </Router>
  );
}
