import React from 'react';
import { Row } from 'react-bootstrap';
import FacebookLogo from '../../images/smallFacebookLogo.png';
import TwitterLogo from '../../images/twitterLogo.png';
import LinkedinLogo from '../../images/linkedinLogo.png';
import styles from '../../styles';
import { useHistory } from 'react-router-dom';

export default function Footer() {
  
  let history = useHistory();

  const changePage = (page) => (e) => {
    e.preventDefault();
    
    history.push(page);
  }

  return (
    <Row style={{ textAlign: 'center', backgroundColor: '#e5ecf2', height: '15vh', fontSize: '0.75em', margin: 0 }}>
      <div className="main-footer" style={{ margin: 'auto', padding: '0px 15px' }}>
        CONNECT WITH US ON: 
        <a href="https://www.facebook.com/FitnessRepairParts/" >
          <img 
            style={styles.socialMediaLogos} 
            src={FacebookLogo} 
            alt="Facebook Logo"
            width="25" 
            height="25" 
          /> 
        </a>
        <a href="https://twitter.com/RepairParts">
          <img 
            style={styles.socialMediaLogos} 
            src={TwitterLogo} 
            alt="Twitter Logo"
            width="25" 
            height="25" 
          /> 
        </a>
        <a href="https://www.linkedin.com/company/542558">
          <img 
            style={styles.socialMediaLogos} 
            src={LinkedinLogo} 
            alt="LinkedIn Logo"
            width="25" 
            height="25" 
          />
        </a>
        <br />
        <a style={styles.footerLink} href="/" onClick={changePage('/')}>HOME</a> 
        : 
        <a style={styles.footerLink} href="/about" onClick={changePage('/about')}>ABOUT</a> 
        : 
        <a style={styles.footerLink} href="/resources" onClick={changePage('/resources')}>RESOURCES</a> 
        : 
        <a style={styles.footerLink} href="contact" onClick={changePage('/contact')}>CONTACT US</a>
        <br />
        Copyright 1995-{new Date().getFullYear()} Fitness Plus Equipment Servies, Inc. All Rights Reserved.
      </div>
    </Row>
  );
}
