const mobileStyles = {
    content: {
      minHeight: '75vh',
      position: 'relative',
      backgroundColor: 'white',
      marginLeft: '5%',
      marginRight: '5%',
      marginBottom: 0,
    },
    contentText: {
      fontSize: '1em'
    },  
    navBarLink: {
      color: 'white',
      fontSize: '1.3em',
      textAlign: 'center',
      borderBottom: '1px solid #004482'
    },
  }
  
  export default mobileStyles;