const styles = {
  content: {
    minHeight: '75vh',
    position: 'relative',
    backgroundColor: 'white',
    marginLeft: '10%',
    marginRight: '10%',
    marginBottom: 0,
    boxShadow: '-30px 0px 75px -65px #000000, 30px 0px 75px -65px #000000'
  },
  contentText: {
    fontSize: '1.25em'
  },
  mainTitle: {
    color: '#004482'
  },
  secondaryTitle: {
    marginTop: 60,
    color: '#79bde9'
  },
  navBarLink: {
    color: 'white',
    fontSize: '1.3em',
    marginRight: 20
  },
  socialMediaLogos: {
    marginLeft: 10,
    marginBottom: 5
  },
  footerLink: {
    color: '#004482',
    margin: '0px 10px'
  },
  FPLogo: {
    display: 'block', 
    margin: 'auto', 
    cursor: 'pointer'
  }
}

export default styles;