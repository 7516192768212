import React from 'react';
import { Container, Jumbotron, ListGroup, Col } from 'react-bootstrap';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import styles from '../../styles';
import mobileStyles from '../../styles/mobile';

export default function About(props) {

  const responsiveStyle = props.isMobile ? mobileStyles : styles;

  return (
    <Container fluid="md">
      <NavBar isMobile={props.isMobile} />
      <Jumbotron style={responsiveStyle.content}>
        <h1 style={styles.mainTitle}>WHO IS FITNESS PLUS?</h1>
        <h3 style={styles.secondaryTitle}>OUR COMPANY HISTORY</h3>
        <p style={responsiveStyle.contentText}>
          In 1995, after spending several years with a major manufacturer as quality control manager,
          John Jacobs decided to start an independent service provider in the St. Louis market. Being
          a meticulous, quality-oriented person, it didn't take long for his business to become the
          largest and most respected in the area. With a focus on reducing customer downtime, Fitness
          Plus began stocking a large inventory of parts for treadmills, steppers, ellipticals, and more.
        </p>
        <p style={responsiveStyle.contentText}>
          The company started out of Jacobs's garage, and it filled the basement when more space was 
          needed. It was soon time to move into a larger space, and in September 2005, Fitness Plus relocated 
          to a 5,000 square foot warehouse in O'Fallon, MO. Business continued to grow at an incredible pace 
          as our customers responded to superior service and quality. In September 2010, Fitness Plus was 
          recognized by Inc. Magazine on their Inc500 list as one of the fastest growing companies in the 
          country and the fastest growing company in the state of Missouri. The incredible pace of growth has
           necessitated more space. We added 3,000 square feet in 2009 and consolidated back into one location 
           by moving into 20,000 square feet located in Wentzville, MO in November 2010.
        </p>
        <p style={responsiveStyle.contentText}>
          In 2016 we outgrew our space once again and moved into 66,000 square feet in the beautiful historic 
          district of St. Charles, MO.
        </p>
        <p style={responsiveStyle.contentText}>
          Fitness Plus is a family of services centered around the fitness industry...
        </p>
        
        <h3 style={styles.secondaryTitle}>OUR MISSION STATEMENT</h3>
        <p style={responsiveStyle.contentText}>
        We WILL be the FIRST THOUGHT and FINAL AUTHORITY with our customer's equipment.
        </p>
        <Col md={7} style={{ padding: 0 }}>
          <ListGroup style={responsiveStyle.contentText}>
            <ListGroup.Item>
              <strong>FINAL AUTHORITY:</strong> With a steadfast focus on excellence, quality, and value, our 
              customers will trust us and desire our advice.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>FIRST THOUGHT:</strong> Our speed, quality, and customer service will be unequalled and unrivaled in our 
              markets, such that the customer always thinks of us first when their equipment breaks.
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Jumbotron>
      <Footer />
    </Container>
  );
}