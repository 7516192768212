import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import FPLogo from '../../images/fitnessPlusLogo.png';
import BlueHeaderBody from '../../images/BlueHeaderBody.jpg';
import HeaderTop from '../../images/HeaderTop.jpg';
import styles from '../../styles';
import mobileStyles from '../../styles/mobile';

export default function NavBar(props) {

  const location = useLocation();
  let history = useHistory();

  const changePage = (page) => (e) => {
    e.preventDefault();
    
    history.push(page);
  }
  
  const responsiveStyle = props.isMobile ? mobileStyles : styles;

  function isActive(page) {
    let style = {
      display: 'inline-block', 
      padding: props.isMobile ? '15px 15px 12px' : '15px 15px 7px',
      font: props.isMobile ? '12px Arial' : '16px Arial'
    }
    if (location.pathname === page) {
      return {
        ...style,
        backgroundImage: 'linear-gradient(#9e9e9e, #595959)',
        color: '#FEC940'
      }
    }

    return {
      ...style,
      color: '#505050'
    }
  }

  return (
    <>
      <Row style={{ backgroundImage: `url(${HeaderTop})`, backgroundRepeat: 'repeat-x', width: '100%', height: 41, margin: 0}}>
        <Col md={6} style={{ textAlign: 'center', padding: 0 }}>
          <a 
            style={isActive('/')} 
            href="/" 
            onClick={changePage('/')}
          >
            <strong>HOME</strong>
          </a>
          <a 
            style={isActive('/about')} 
            href="/about" 
            onClick={changePage('/about')}
          >
            <strong>ABOUT</strong>
          </a>
          <a 
            style={isActive('/resources')} 
            href="/resources" 
            onClick={changePage('/resources')}
          >
            <strong>RESOURCES</strong>
          </a>
          <a 
            style={isActive('/contact')} 
            href="/contact" 
            onClick={changePage('/contact')}
          >
            <strong>CONTACT</strong>
          </a>
        </Col>
      </Row>
      <Row style={{ backgroundImage: `url(${BlueHeaderBody})`, backgroundRepeat: 'repeat-x', width: '100%', height: 130, margin: 0 }}>
        <Col md={6} style={{ padding: 0 }}>
          <Image src={FPLogo} href="/" onClick={changePage('/')} width={props.isMobile ? "310" : "407"} height={props.isMobile ? "90" : "124"} style={styles.FPLogo} />
        </Col>
        <Col md={6} style={{ textAlign: 'center', color: 'white', marginTop: props.isMobile ? 0 : 10 }}>
          Phone: 636.379.1952
        </Col>
      </Row>
    </>
  );
}
