import React from 'react';
import { Container, Jumbotron, ListGroup, Col } from 'react-bootstrap';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import styles from '../../styles';
import mobileStyles from '../../styles/mobile';

export default function Home(props) {

  const responsiveStyle = props.isMobile ? mobileStyles : styles;

  return (
    <Container fluid="md">
      <NavBar isMobile={props.isMobile} />
      <Jumbotron style={responsiveStyle.content}>
        <h1 style={styles.mainTitle}>SERVANT TECHNOLOGY FOR THE FITNESS INDUSTRY</h1>
        <h3 style={styles.secondaryTitle}>WE BUILD CUSTOM TECHNOLOGY FOR THE SERVANTS OF THE FITNESS INDUSTRY.</h3>
        <p style={responsiveStyle.contentText}>
          Originally formed in the St. Louis area in 1995, <strong>FITNESS PLUS</strong> was created 
          as an independent service provider to repair exercise equipment for commercial
           and residential customers. In 2003 we began developing technology solutions 
           to serve the services side of the industry. With over 20 years of experience 
           on the services side of things, we are innovating products and services for 
           the less glamorous side of the fitness industry.
        </p>
        <p style={styles.contentText}>
          Our current solutions include (more to come):
        </p>
        <Col md={4} style={{ padding: 0 }}>
          <ListGroup style={responsiveStyle.contentText}>
            <ListGroup.Item action href="https://www.fitnessrepairparts.com" style={{ backgroundColor: '#E2F0FA', textDecoration: 'underline' }}>
              <strong>FitnessRepairParts.com</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              3rd Party Logistics
            </ListGroup.Item>
            <ListGroup.Item>
              Branded Platform
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Jumbotron>
      <Footer />
    </Container>
  );
}
