const getConfigValues = () => ({
    inProduction: process.env.NODE_ENV === 'production',
    google_analytics_id: 'UA-975398-' + (process.env.NODE_ENV === 'production' ? '5' : '10'),
    release_name: process.env.RELEASE_NAME,
  });
  
  export function config(key) {
    if (key in getConfigValues()) {
      if (typeof getConfigValues()[key] === 'undefined' && !config('inProduction')) {
        console.warn('[config] Accessing config value "' + key + '" but the value is undefined. This most likely means it is present in the JS config object, but missing from another source like an env. You may have forgotten to restart your build.');
      }
  
      return getConfigValues()[key];
    } else {
      if (!config('inProduction')) {
        console.error('[config] Attempted to access config value "' + key + '" which does not exist. You may have mistyped the key, or forgotten to set it.');
      }
    }
  }